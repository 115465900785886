import React from "react";
import { Root } from "./style";

import RawHTML from "components/Common/RawHTML";
import JoinTheCommunities from "components/Common/JoinTheCommunities";
import ShareLinks from "components/Common/ShareLinks";

const Video = ({ location, pageContext: { video } }) => {
  return (
    <Root className="body-width">
      <div
        style={{
          margin: "40px 0 60px",
          position: "relative",
          paddingBottom: "56.25%",
          height: 0,
          overflow: "hidden",
          borderRadius: "30px",
        }}
      >
        {video.dailyMotionID ? (
          <iframe
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              left: "0px",
              top: "0px",
              overflow: "hidden",
            }}
            frameBorder="0"
            type="text/html"
            src={`https://www.dailymotion.com/embed/video/${video.dailyMotionID}?autoplay=1`}
            width="100%"
            height="100%"
            allowFullScreen
            allow="autoplay"
          ></iframe>
        ) : (
          ""
        )}
        {video.youTubeID ? (
          <iframe
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              left: "0px",
              top: "0px",
              overflow: "hidden",
            }}
            frameBorder="0"
            src={`https://www.youtube.com/embed/${video.youTubeID}`}
            width="100%"
            height="100%"
            allowFullScreen
          ></iframe>
        ) : (
          ""
        )}
      </div>
      <h1>{video.title}</h1>
      <ShareLinks location={location} item={video} />
      <RawHTML className="content" html={video.content} />
      <JoinTheCommunities />
    </Root>
  );
};

export default Video;
