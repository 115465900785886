import styled from 'styled-components';

import vars from 'variables';

const Root = styled.div`
  div.image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .share {
    margin-bottom: -10px;
    display: flex;
    align-items: baseline;
    line-height: 100%;
  }

  .share span {
    color: #AAA;
    align-self: center;
    text-transform: uppercase;
  }

  .share a {
    margin-right: 12px;
  }

  ${vars.desktop.mediaQuery} {
    div.image {
      height: 700px;
      margin: 90px 0;
      border-radius: 20px;
    }

    h1 {
      font-size: 3.55rem;
      padding-right: 200px;
      line-height: 110%;
    }

    .share {
      margin-top: 30px;
    }

    .share span {
      margin-right: 20px;
      font-size: 1.025rem;
    }

    .content {
      padding-left: 230px;
    }

    .content h2 {
      margin: 85px 0 25px;
      font-weight: 700;
      font-size: 1.6rem;
    }

    .content p {
      color: #AAA;
      line-height: 165%;
    }

    .join-the-communities {
      margin-top: 115px;
    }
  }

  ${vars.mobile.mediaQuery} {
    div.image {
      height: 250px;
      margin: 50px 0 20px;
      border-radius: 10px;
    }

    h1 {
      font-size: 1.25rem;
      line-height: 140%;
    }

    .share {
      margin: 20px 0 50px;
    }

    .share span {
      margin-right: 12px;
    }

    .content h2 {
      margin: 45px 0 15px;
      font-weight: 700;
      font-size: 0.85rem;
      line-height: 155%;
    }

    .join-the-communities {
      margin: 45px 0 65px;
    }
  }
`;

export { Root };
